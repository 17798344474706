import {bankNameList} from "@/enums/bankNameEnum";

function getBank(key){
    return bankNameList.filter(item => {
        if(item.value==key){
            return item
        }
    });
}

export default {
    getBank
};
