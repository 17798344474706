<template>
  <div>
    <div class="withdraw" v-if="pageNumners[0].isShow">
      <!-- 提现概览 -->
      <div class="withdraw-views">
        <div class="withdraw-views-header">
          <strong><span v-if="currentIdentity==1">[个人]</span><span v-else-if="currentIdentity==2">[企业]</span>概括概览</strong>
        </div>
        <div class="withdraw-views-bottom">
          <div class="withdraw-views-bottom1">
            <span>提现中</span>
            <p><strong>{{ walletInfo.freeze_in_yuan }}</strong><span>元</span></p>
            <button @click="changePages('list')">提现设置</button>
          </div>
          <div class="withdraw-views-bottom2">
            <span>可提取金额</span>
            <p><strong>{{ walletInfo.available_plt_yuan }}</strong><span>元</span></p>
            <el-tooltip class="item" effect="dark" content="此功能暂未开放" placement="top-start">
              <button>申请提现</button>
              <!--              @click="changePages('towithdraw')"-->
            </el-tooltip>
          </div>
          <div class="withdraw-views-bottom3">
            <span>已提现</span>
            <p><strong>{{ walletInfo.acc_withdraw_yuan }}</strong><span>元</span></p>
          </div>
        </div>
      </div>
      <!-- 提现明细 -->
      <div class="withdrae-detail">
        <div class="withdrae-detail-header">
          <strong>提现明细</strong>
        </div>
        <div class="withdrea-detail-bottom">
          <el-table
              :data="detailTableData"
              border style="width: 100%"
              :header-cell-style="{
              'text-align': 'center',
              height: '36px',
              color: '#000',
              'font-size': '14px',
              background: '#f4f4f4',
            }"
              :cell-style="{
              'text-align': 'center',
              height: '50px',
              color: '#575757',
              'font-size': '12px',
            }"
              stripe>
            <el-table-column prop="created_at" label="申请日期"/>
            <el-table-column prop="bill_type_name" label="类型"/>
            <el-table-column prop="amount_yuan" label="金额"/>
            <el-table-column prop="bis_status_name" label="状态"/>
            <el-table-column prop="memo" label="备注"/>
            <el-table-column prop="is_default" label="操作"/>
          </el-table>

          <div class="pagination-div">
            <el-pagination
                next-text="下一页"
                prev-text="上一页"
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :total="detailPagination.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="detailPagination.page_size"/>
          </div>
        </div>
      </div>
    </div>

    <!--    新增提现账户-->
    <div class="addCount-border" v-if="pageNumners[1].isShow">
      <div class="addCount">
        <div class="addCount-header">
          <p>
            <strong>新增提现账号</strong>
            <Isvg icon-class="exclamation-mark"/>
            <span>提现和您认证的信息互相绑定，请使用您认证的实名信息进行提交！</span>
            <span class="b" style="float: right;margin-right: 40px; cursor: pointer;font-size: 14px"
                  @click="changePages('list')">返回</span>
          </p>
        </div>

        <div class="addCount-bottom">
          <el-form :model="withdrawForm" :rules="withdrawRules" ref="withdrawForm" label-width="110px">
            <div class="addCount-bottom-radio">
              <el-radio-group v-model="withdrawForm.type" @change="changeType">
                <div class="addCount-bottom-radio1">
                  <Isvg icon-class="task-ali-pay"/>
                  <br/>
                  <el-radio :label="payTypes.ali">支付宝</el-radio>
                </div>
                <div class="addCount-bottom-radio1">
                  <Isvg icon-class="task-wechat-pay"/>
                  <br/>
                  <el-radio :label="payTypes.wx">微信</el-radio>
                </div>
                <div class="addCount-bottom-radio1">
                  <Isvg icon-class="task-bank-pay"/>
                  <br/>
                  <el-radio :label="payTypes.bank">银行卡</el-radio>
                </div>
              </el-radio-group>
            </div>
            <!-- 微信 -->
            <div class="addCount-bottom-wechat" v-if="withdrawForm.type == payTypes.wx">
              <el-form-item label="认证主体" prop="certification_id">
                <el-select v-model="withdrawForm.certification_id" style="width: 100%" clearable
                           placeholder="请选择认证主体实名" @change="refreshCode">
                  <el-option v-for="(item, index) in realNameOptions" :key="index" :value="item.id"
                             :label="item.name">
                    <span class="the-option-item">
                      <span class="the-option-item-title">
                        <Isvg v-if="item.type1==1" icon-class="authentication"/>
                        <Isvg v-if="item.type1==2" icon-class="enterprise-certification"/>
                        {{ item.name }}
                      </span>
                      <span class="the-option-item-tag">{{ item.type1_name }}认证</span>
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>

              <div class="loadingDiv" v-if="wechatData.qrcode_url!=''">
                <div v-if="qrcodeStatus!=''">
                  <i :style="{ 'color': qrcodeStatus!='success'?'#d4237a':'#09bb07' }" :class="qrCodeStatusIcon"></i>
                  <p :style="{ 'color': qrcodeStatus!='success'?'#d4237a':'#09bb07' }">{{ qrcodeStatusMsg }}</p>
                  <p :style="{ 'color': qrcodeStatus!='success'?'#d4237a':'#09bb07', 'cursor': 'pointer' }"
                     @click="refreshCode(currentCertificationId)"><i class="el-icon-refresh"></i> 点击重试 </p>
                </div>
                <img v-if="wechatData.qrcode_url!=''" :src="wechatData.qrcode_url" alt=""/>
              </div>
              <p class="r">*请使用当前主体实名的微信扫描二维码，否则将无法通过提现审核</p>
            </div>
            <!-- 支付宝 -->
            <div class="addCount-bottom-Alipay" v-if="withdrawForm.type == payTypes.ali">
              <el-form-item label="认证主体" prop="certification_id">
                <el-select v-model="withdrawForm.certification_id" style="width: 100%" clearable
                           placeholder="请选择认证主体实名">
                  <el-option v-for="(item, index) in realNameOptions" :key="index" :value="item.id"
                             :label="item.name">
                    <span class="the-option-item">
                      <span class="the-option-item-title">
                        <Isvg v-if="item.type1==1" icon-class="authentication"/>
                        <Isvg v-if="item.type1==2" icon-class="enterprise-certification"/>
                        {{ item.name }}
                      </span>
                      <span class="the-option-item-tag">{{ item.type1_name }}认证</span>
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付宝账户" prop="bank_num">
                <el-input v-model="withdrawForm.bank_num" placeholder="请输入支付宝账号" clearable/>
              </el-form-item>
            </div>

            <div class="addCount-bottom-bank" v-loading="searchBankLoading" v-if="withdrawForm.type == payTypes.bank">
              <el-form-item label="认证主体" prop="certification_id">
                <el-select v-model="withdrawForm.certification_id" style="width: 100%" clearable
                           placeholder="请选择认证主体实名">
                  <el-option v-for="(item, index) in realNameOptions" :key="index" :value="item.id"
                             :label="item.name">
                    <span class="the-option-item">
                      <span class="the-option-item-title">
                        <Isvg v-if="item.type1==1" icon-class="authentication"/>
                        <Isvg v-if="item.type1==2" icon-class="enterprise-certification"/>
                        {{ item.name }}
                      </span>
                      <span class="the-option-item-tag">{{ item.type1_name }}认证</span>
                    </span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="银行名称" prop="bank_name">
                <el-select v-model="withdrawForm.bank_name" style="width: 100%" filterable placeholder="请输入银行名称"
                           clearable>
                  <el-option
                      v-for="(bankinfo, bindex) in BankNames"
                      :key="bindex"
                      :label="bankinfo.text"
                      :value="bankinfo.text"/>
                </el-select>
              </el-form-item>
              <el-form-item label="银行卡号" prop="bank_num">
                <el-input v-model="withdrawForm.bank_num" placeholder="请输入银行卡号" clearable/>
              </el-form-item>
            </div>

            <div v-if="withdrawForm.type!=payTypes.wx" class="the-radio-div">
              <el-form-item label="是否设置为默认账户" prop="is_default" label-width="150px">
                <el-radio-group size="mini" v-model="withdrawForm.is_default">
                  <el-radio label="n">否</el-radio>
                  <el-radio label="y">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="addCount-bottom-bank" v-if="withdrawForm.type!=payTypes.wx">
              <el-button size="small" type="primary" @click="submitForm('create')">提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 提现账号设置 -->
    <div class="withdraw-account" v-if="pageNumners[2].isShow">
      <div class="withdraw-account-header">
        <div class="withdraw-account-header-center">
          <strong>提现账号</strong>
          <div class="withdraw-account-header-center-right">
            <span class="b" @click="changePages('add')">添加提现账号</span>
            <el-divider direction="vertical"/>
            <span class="b" @click="changePages('index')">返回</span>
          </div>
        </div>
      </div>
      <div class="withdraw-account-tab">
        <el-table
            :data="tableData"
            border style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              height: '36px',
              color: '#000',
              'font-size': '14px',
              background: '#f4f4f4',
            }"
            :cell-style="{
              'text-align': 'center',
              height: '50px',
              color: '#575757',
              'font-size': '12px',
            }"
            stripe>
          <el-table-column prop="type_name" label="账号类型" width="80">
            <template slot-scope="scope">
              <Isvg icon-class="task-wechat-pay" class-name="listSvg" v-if="scope.row.type==payTypes.wx"/>
              <Isvg icon-class="task-ali-pay" class-name="listSvg" v-if="scope.row.type==payTypes.ali"/>
              <Isvg icon-class="task-bank-pay" class-name="listSvg" v-if="scope.row.type==payTypes.bank"/>
              {{ scope.row.type_name }}
            </template>
          </el-table-column>
          <el-table-column prop="bank_num" label="账号" width="250"/>
          <el-table-column prop="bank_name" label="银行名称" width="150"/>
          <el-table-column prop="name" label="持有人" width="160"/>
          <el-table-column prop="is_default" label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-popconfirm title="您确定要删除此账户吗？" @confirm="toDelete(scope.row)" icon="el-icon-info" icon-color="red">
                <el-link size="mini" slot="reference" type="danger">
                  <i class="el-icon-delete"></i> 删除
                </el-link>
              </el-popconfirm>
              <el-divider direction="vertical"/>
              <el-link style="font-size: 12px" @click="toChange(scope.row)" v-show="scope.row.is_default=='n'"
                       type="warning">设为默认账户
              </el-link>
              <el-link v-show="scope.row.is_default=='y'" type="primary">默认账户</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--    去提现-->
    <div class="addCount-border" v-if="pageNumners[3].isShow">
      <div class="addCount">
        <div class="addCount-header">
          <p>
            提现
            <span class="b" style="float: right;margin-right: 40px; cursor: pointer;font-size: 14px"
                  @click="changePages('index')">返回</span>
          </p>
        </div>
        <div class="addCount-bottom">
          <div class="withdrawDiv">
            <el-form :model="toWithdrawForm" ref="toWithdrawForm" label-width="100px">
              <el-form-item prop="money" label="提现金额">
                <el-input v-model="toWithdrawForm.money"/>
              </el-form-item>

              <el-form-item prop="money" label="提现金额">
                <el-select v-model="toWithdrawForm.bank_id">
                  <el-option :value="bank.id" :label="bank.bank_num +' - ' + bank.bank_name"
                             v-for="(bank, bankindex) in tableData" :key="bankindex">
                    <Isvg icon-class="task-bank-pay"/>
                    {{ bank.bank_num }} - {{ bank.bank_name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getUserAllAuthList} from "@/api/auth";
import {
  createUserBank,
  deleteUserBank,
  changeUserBank,
  getUserBankList,
  getWallet,
  getWechatQrCode,
  getWechatQrCodeQuery, getWalletDetails
} from "@/api/user";
import axios from "axios";
import getBankName from "@/utils/getBankName";
import {bankNameList} from "@/enums/bankNameEnum";

export default {
  computed: {
    BankNames() {
      return bankNameList
    },
    currentIdentity() {
      return this.$store.state.identity
    }
  },
  watch: {
    currentIdentity( newval, oldval ) {
      if( oldval!=null ){
        this.loadWallet()
        this.detailSearchParams.wallet_type = newval
        this.loadDetails(this.detailSearchParams)
      }
    }
  },
  data() {
    // 验证银行卡
    var bankAccountValid = (rule, value, callback) => {
      if (this.withdrawForm.type == this.payTypes.ali) {
        // 支持邮箱和手机号
        let reg = /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/;
        if (!reg.test(value)) {
          return callback(new Error('支付宝账号错误'))
        }
        return callback()
      }
      const strBin = '10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99'
      if (!value) {
        return callback(new Error('收款账户不能为空'))
      } else if (!Number.isInteger(+value)) {
        callback(new Error('银⾏卡号必须全为数字'))
      } else if (value.trim().length < 12 || value.trim().length > 19) {
        callback(new Error('银⾏卡号长度必须在12到19之间'))
      } else if (strBin.indexOf(value.substring(0, 2)) === -1) {
        callback(new Error('银⾏卡号开头6位不符合规范'))
      } else {
        this.searchBankName()
        callback()
      }
    }
    return {
      payTypes: {
        bank: 1,// 银行卡
        ali: 2,// 支付宝
        wx: 3,// 微信
        ext: 4// 第三方
      },
      walletInfo: {},
      realNameOptions: [],
      detailTableData: [],
      detailPagination: {
        page: 1,
        page_size: 10,
        total: 0,
        page_num: 1
      },
      detailSearchParams: {
        wallet_type: 1,
        page: 1,
        page_size: 10,
        type: 'o',
        kw: ''
      },
      withdrawForm: {
        certification_id: '',
        bank_name: '',
        bank_num: '',
        is_default: 'n',
        type: 2,
      },
      withdrawRules: {
        certification_id: [
          {required: true, message: '请选择您的认证主体', trigger: 'change'},
        ],
        bank_num: [
          {required: true, validator: bankAccountValid, trigger: 'blur'},
        ],
        bank_name: [
          {required: true, message: '请输入银行名称', trigger: 'change'},
        ]
      },
      toWithdrawForm: {},
      tableData: [],
      pageNumners: [
        {page: 'index', isShow: true},
        {page: 'add', isShow: false},
        {page: 'list', isShow: false},
        {page: 'towithdraw', isShow: false},
      ],
      searchBankLoading: false,
      toWithdrawId: 0,
      wechatData: {
        qrcode_url: ''
      },
      wechatTimer: null,
      wechatTimerSeconds: 2000,
      queryTimes: 0,
      qrcodeStatus: '',
      qrcodeStatusMsg: '',
      qrCodeStatusIcon: '',
      qrcodeLoadingDiv: false,
      currentCertificationId: 0
    };
  },
  mounted() {
    this.loadRealNameList()
    this.loadWallet()
  },
  methods: {
    changePages(pageNumber) {
      this.pageNumners.map(page => {
        if (pageNumber == page.page) {
          page.isShow = true
          switch (pageNumber) {
            case 'list':
              this.loadBankList()
              break
            case 'towithdraw':
              this.loadBankList()
              break
          }
        } else {
          page.isShow = false
        }
      })
    },
    changeType(val) {
      switch (val) {
        case this.payTypes.wx:
          break
        default:
          clearInterval(this.wechatTimer)
          this.wechatData = {
            qrcode_url: ''
          }
          break
      }

      this.$refs.withdrawForm.resetFields();
      this.clearForm(val)
    },
    clearForm(type = 2) {
      this.withdrawForm = {
        certification_id: '',
        bank_name: '',
        bank_num: '',
        is_default: 'n',
        type: type,
      }
    },
    submitForm() {
      this.$refs.withdrawForm.validate((result) => {
        if (result) {
          this.submitToCreateBank(this.withdrawForm)
        }
      })
    },
    refreshCode(val) {
      this.currentCertificationId = val
      clearInterval(this.wechatTimer)
      if (val > 0) {
        this.getWxQrcode(val)
      } else {
        this.wechatData = {
          qrcode_url: ''
        }
      }
    },
    async toDelete(row) {
      const res = await deleteUserBank({id: row.id, type: 2})
      if (res.statusCode == 200) {
        this.loadBankList()
      }
    },
    async toChange(row) {
      const res = await changeUserBank({id: row.id, type: 1})
      if (res.statusCode == 200) {
        this.loadBankList()
      }
    },
    async loadWallet() {
      const res = await getWallet({wallet_type: this.currentIdentity})
      if (res.statusCode == 200) {
        this.walletInfo = res.data
      }
    },
    async loadDetails(data) {
      let res = await getWalletDetails(data);
      if (res.statusCode == 200) {
        this.detailTableData = res.data.list
        this.detailPagination.page = Number(res.data.pages.page)
        this.detailPagination.page_size = Number(res.data.pages.page_size)
        this.detailPagination.total = Number(res.data.pages.total)
        this.detailPagination.page_num = Number(res.data.pages.page_num)
      }
    },
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {
      this.detailSearchParams.page = val
      this.loadDetails(this.detailSearchParams)
    },
    async submitToCreateBank(data) {
      const res = await createUserBank(data)
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '操作成功!'
        })
        this.clearForm(data.type)
        this.changePages('list')
      } else {
        this.$message.error('操作失败')
      }
    },
    async loadRealNameList() {
      const res = await getUserAllAuthList()
      if (res.statusCode == 200) {
        if (res.data.length > 0) {
          // 默认取第一个
          this.realNameOptions = res.data
        }
      }
    },
    async loadBankList() {
      const res = await getUserBankList()
      if (res.statusCode == 200) {
        this.tableData = res.data.list
      }
    },
    async searchBankName() {
      this.searchBankLoading = true
      // 6212264010013631038
      let _this = this
      axios.get("https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=" + this.withdrawForm.bank_num + "&cardBinCheck=true")
          .then(function (result) {
            _this.searchBankLoading = false
            const theBankName = getBankName.getBank(result.data.bank)
            _this.withdrawForm.bank_name = theBankName[0].text
          })
    },
    async getWxQrcode(certificationId) {
      const res = await getWechatQrCode({certification_id: certificationId})
      if (res.statusCode == 200) {
        this.wechatData = res.data;
        this.qrcodeStatus = ''
        let _this = this
        setTimeout(function () {
          _this.wechatTimer = setInterval(_this.getWxQrcodeQuery, _this.wechatTimerSeconds)
        }, 1000);
      }
    },
    async getWxQrcodeQuery() {
      this.queryTimes++;
      let querySeconds = (this.queryTimes * this.wechatTimerSeconds) / 1000; //请求的秒数
      //请求超时
      if (querySeconds > this.wechatData.expire_seconds) { //
        clearInterval(this.wechatTimer);
        this.qrcodeStatus = 'timeout'
        this.qrcodeStatusMsg = '请求已超时'
        this.qrCodeStatusIcon = 'el-icon-circle-close'
        this.qrcodeLoadingDiv = true
        return;
      }

      const res = await getWechatQrCodeQuery({code: this.wechatData.code})
      if (res.statusCode == 200 && res.data?.result == 1) {
        clearInterval(this.wechatTimer);
        this.qrcodeLoadingDiv = true
        this.qrcodeStatus = 'success'
        this.qrcodeStatusMsg = '账户绑定成功'
        this.qrCodeStatusIcon = 'el-icon-circle-check'
        let _this = this
        setTimeout(function () {
          _this.changePages('list')
        })
      } else if (res.data?.result == 0) {
        this.qrcodeLoadingDiv = true
        this.qrcodeStatus = 'error'
        this.qrcodeStatusMsg = res.data?.msg
        this.qrCodeStatusIcon = 'el-icon-circle-close'
        clearInterval(this.wechatTimer);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  // 提现概览
  .withdraw-views {
    width: 940px;
    height: 300px;
    background: #ffffff;

    .withdraw-views-header {
      height: 76px;
      border-bottom: 1px solid #b5b5b5;

      strong {
        position: relative;
        top: 40px;
        left: 40px;
      }
    }

    .withdraw-views-bottom {
      display: flex;
      justify-content: space-between;
      padding: 0px 90px;
      margin-top: 40px;

      .withdraw-views-bottom1,
      .withdraw-views-bottom2,
      .withdraw-views-bottom3 {
        text-align: center;

        span {
          font-size: 14px;
          color: #575757;
        }

        p {
          margin: 25px 0;

          strong {
            font-size: 36px;
          }
        }

        button {
          width: 85px;
          height: 24px;
          background-color: #f8f8f8;
          border-radius: 12px;
          color: #575757;
        }
      }

      .withdraw-views-bottom2 {
        button {
          background-color: #00a2e6;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }

  //提现明细
  .withdrae-detail {
    margin-top: 20px;
    width: 940px;
    min-height: 700px;
    background: #ffffff;

    .withdrae-detail-header {
      height: 79px;
      border-bottom: 1px solid #b5b5b6;
      margin-top: 20px;

      strong {
        position: relative;
        left: 40px;
        top: 40px;
      }
    }

    .withdrea-detail-bottom {
      padding: 20px;

      span {
        font-size: 14px;
        color: #575757;
      }
    }
  }
}

.addCount {
  width: 940px;
  min-height: 940px;
  background: #ffffff;

  .addCount-header {
    height: 79px;
    border-bottom: 1px solid #b5b5b5;

    p {
      position: relative;
      top: 40px;
      left: 40px;
      width: 900px;

      strong {
        font-size: 18px;
      }

      span {
        font-size: 12px;
      }

      svg {
        width: 16px;
        height: 16px;
        margin: 0 10px 0 20px;
      }
    }
  }

  .addCount-bottom {
    margin-top: 100px;

    .withdrawDiv {
      margin: 0 auto;
      width: 350px;

      /deep/ .el-select {
        width: 100%;
      }
    }

    .addCount-bottom-radio {
      width: 300px;
      margin: 0 auto;

      .addCount-bottom-radio1 {
        text-align: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .addCount-bottom-wechat {
      width: 350px;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 20px;

      .loadingDiv {
        margin: 0 auto;
        width: 170px;
        height: 170px;
        position: relative;

        div {
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          text-align: center;
          position: absolute;

          i {
            margin-top: 60px;
          }

          .el-icon-circle-close {
            color: #d4237a;
          }

          p {
            width: unset;
            margin-top: 20px;
            font-weight: bold;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        width: 360px;
        font-size: 12px;
        margin-top: 40px;
      }
    }

    .addCount-bottom-Alipay {
      margin: 0 auto;
      width: 390px;
      text-align: center;
    }

    .addCount-bottom-bank {
      margin: 0 auto;
      width: 385px;
      text-align: center;

      button {
        width: 200px;
        background-color: #00a2e6;
      }
    }
  }
}

.withdraw-account {
  background-color: #ffffff;
  min-height: 940px;

  .withdraw-account-header {
    height: 76px;
    display: flex;

    .withdraw-account-header-center {
      width: 860px;
      display: flex;
      margin: 40px 0px 0 40px;
      justify-content: space-between;

      span {
        font-size: 14px;
        cursor: pointer;
      }

      strong {
        font-size: 18px;
      }
    }
  }

  .withdraw-account-tab {
    width: 860px;
    margin: 0 auto;
    margin-top: 20px;

    height: 500px;
  }
}

/deep/ .el-radio__inner {
  width: 12px;
  height: 12px;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #00a2e6;
  background-color: #00a2e6;
}

/deep/ .el-radio__label {
  font-size: 14px;
  color: #575757;
}

/deep/ .el-radio {
  margin-top: 10px;
}

/deep/ .el-radio-group {
  display: flex;
  justify-content: space-between;
}

label {
  display: block;
  margin-bottom: 40px;
}

.cs {
  cursor: pointer;
}

/deep/ .el-popover__reference-wrapper span {
  font-size: 12px;
}

.listSvg {
  width: 14px;
  height: 14px;
}

.the-option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .the-option-item-title {
    display: flex;
    align-items: center;

    svg {
      margin: 0px 5px 0px 0px;
    }
  }

  .the-option-item-tag {
    color: #8492a6;
    font-size: 13px
  }
}

.the-radio-div {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
}
</style>
